import styled from "styled-components";

export const MainBody = styled.div`
    classname: illustration-section-01;
    // width: 100vw;
    // height: 100vh;
    display: block;
    flex-direction: row;
    justify-content: space-between;
    // background: linear-gradient(
    //     65deg,
    //     rgba(56, 14, 128, 255),
    //     rgba(5, 58, 177, 255),
    //     rgba(234, 61, 229, 255),
    //     rgba(9, 177, 218, 255),
    //     rgba(48, 54, 145, 255),
    //     rgba(133, 12, 142, 255),
    //     rgba(232, 52, 190, 255),
    //     rgba(212, 34, 219, 255),
    //     rgba(9, 177, 218, 255)
    // );
`;
//purple
//dark blue
//pnk
//cyan
//indigo
//dark pink
//pink
export const MainBackgroundCard = styled.div`
    align-self: center;
    // width: 75vw;
    height: 100%;
    // border-radius: 20px;
    background: rgba(21, 23, 25, 255);
    // box-shadow: 5px 15px 15px rgba(236,63,228,255);
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 255);
    // position: absolute;
    // margin-top: 20px;
`;
